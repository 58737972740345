import * as BABYLON from 'babylonjs';
import SocketIOController from './SocketIOController';
import { SocketIOSubscriber } from './SocketIOController';
import { LiveSwitchController } from '../liveSwitch/LiveSwitchController';
import { LocalAvatarController } from '../controllers/LocalAvatarController';
import { AvatarData } from '../avatars/AvatarData';
import { RemoteAvatarController } from '../controllers/RemoteAvatarController';
import { Ride } from './Ride';
import * as GUI from 'babylonjs-gui';
import 'babylonjs-loaders';
import { PBRMaterial, Vector3, Mesh, Texture, HemisphericLight, NodeMaterialBlockConnectionPointMode, Tools, Scalar, Color4, Color3} from 'babylonjs';
import { JumbotronController } from '../controllers/JumbotronController';
import { TeleportController } from '../controllers/TeleportController';
import './LobbyStageHelper';
import { RoomCodeData, RoomCodeStruct } from '../utilities/RoomCodeData';
import { PlayerClickNavigationController } from '../controllers/PlayerClickNavigationController';
import { NavMeshHandler } from '../controllers/NavMeshHandler';
import { MessageBus } from '../utilities/MessageBus';
import { LobbyDefaultConfig } from '../utilities/LobbyConfig';
import { RemoteAvatarInstanceFactory } from '../controllers/RemoteAvatarInstanceFactory';
import AvatarColor from '../components/Intake/AvatarColor';
let faker = require('faker');

export class LobbyScene implements SocketIOSubscriber {
    private ride : Ride;

    private localAvatarController: LocalAvatarController;
    private remoteAvatarController : RemoteAvatarController;

    private advancedTexture : GUI.AdvancedDynamicTexture;
    private fullScreenRect :GUI.Rectangle

    private jumbotronController : JumbotronController;

    private currentScreenShareChannel : string = "";
    private currentSlideNumber : number = 0;

    elapsedTimeSinceEnableKey : number = 0;

    private loadedRoom : string;
    private debugAmbient : Color3 = new BABYLON.Color3(.5, .5, .5);
    private defaultAmbient : Color3 = new BABYLON.Color3(0,0,0);

    private drydockAvatarFactory : RemoteAvatarInstanceFactory = null;
    private spawnAmount : number = 10;
    private spawnRadius : number = 10;
    private spawnedDrydockAvatars : number = 0;
    private drydockHemisphereLight : HemisphericLight;

    constructor(private engine: BABYLON.Engine, private scene: BABYLON.Scene, private camera: BABYLON.FreeCamera, private canvas: HTMLCanvasElement, private socketIOController: SocketIOController, private liveSwitchController: LiveSwitchController, private avatarData : AvatarData) {

        this.socketIOController.AddListener(this);

        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.fullScreenRect = new GUI.Rectangle();
        this.fullScreenRect.alpha = 1;
        this.fullScreenRect.background = "Black";

        this.advancedTexture.addControl(this.fullScreenRect);
        
        //this.scene.debugLayer.show();

        //this.scene.registerBeforeRender(this.addAlpha);

        //Basic Camera movement and Physics
        this.camera.checkCollisions = true;
        this.camera.applyGravity = true;
        this.camera.ellipsoid = new BABYLON.Vector3(0.1, .9, 0.1);
        this.camera.position.z = 7;
        this.camera.maxZ = 500;

        this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 1);

        this.scene.gravity = new BABYLON.Vector3(0, -0.3, 0);    
        this.scene.collisionsEnabled = true;

        //Load Environment and nav mesh functionality
        this.LoadEnvironment().then(()=>{       

            //Focus canvas
            this.canvas.focus();

            document.getElementById('renderCanvas')?.focus();   
            
            //Star Video Streams and Feeds
            this.liveSwitchController.connect(avatarData.micEnabled, avatarData.cameraEnabled, this.avatarData.roomID).then((result)=>{
                
                

                //Join the game on the socket
                this.socketIOController.Connect(avatarData);

                 /*
                    this.socketIOController.RequestRoomVariableData()
                    .then((variableData)=>{
                        console.log("variableData");
                        console.log(variableData);
                    });
                    */


                //Get my avatar up and running
                this.localAvatarController = new LocalAvatarController(this.engine, this.scene, this.camera, this.socketIOController);


                //Get the remote controller running
                this.remoteAvatarController = new RemoteAvatarController(this.engine, this.scene, this.camera, this.socketIOController, this.liveSwitchController, this.localAvatarController, avatarData.userID);
                this.remoteAvatarController.load();

                this.remoteAvatarController.subscribeLiveswitchController(); 

                this.socketIOController.RequestAllData();




            }).fail((ex)=>{
                console.log("Failed to connect to Liveswitch");
                console.log(ex);

                alert("There was a problem conencting to the media server.");
            });           

            //Add the ride
            //this.ride = new Ride(this.engine,this.scene,this.camera,this.socketIOController, this.localAvatarController);

            //let webcamGUI = new WebcamGUI(this.engine, this.scene, this.canvas, this.avatarData, this.liveSwitchController.localMediaController);

            this.scene.registerBeforeRender(this.removeAlpha);

            MessageBus.Raise("OnFullscreenCanvasLoadComplete", null);

           

        });    
        
        
        this.scene.onKeyboardObservable.add((kbInfo) => {
                                
            switch(kbInfo.type){
                case BABYLON.KeyboardEventTypes.KEYDOWN:

                    //Check for the enable key
                    if(kbInfo.event.key == "p"){
                        this.elapsedTimeSinceEnableKey = 0;
                    }

                    if(this.elapsedTimeSinceEnableKey < 1500){
                        if(kbInfo.event.key == "y"){
                            console.log("Y");
                            let screenshareChannelName = this.avatarData.userID + "_screen";
                            this.liveSwitchController.startScreenShare(screenshareChannelName)
                            .then(()=>{
                                console.log("Send All: " + screenshareChannelName);
                                this.socketIOController.SetServerVariable("jumbotronScreenShare",{screenshareChannelName: screenshareChannelName});

                                this.jumbotronController.startScreenShare(this.liveSwitchController.getLocalScreenShareHTMLElement());
                            }).catch((ex)=>{
                                console.log("Failed starting screen share");
                                console.log(ex);
                            });
                        
                        }
                    }

                break;    
            }
        });

        if(LobbyDefaultConfig.DevelopmentMode){
            this.scene.onKeyboardObservable.add((kbInfo) => {
                                
                switch(kbInfo.type){
                    case BABYLON.KeyboardEventTypes.KEYDOWN:
    
                    if(kbInfo.event.key == "i" || kbInfo.event.key == "I"){
                        if(scene.debugLayer.isVisible()) 
                        {
                            scene.debugLayer.hide();
                            canvas.focus();
                        }
                        else {
                            scene.debugLayer.show();
                            canvas.focus();
                        }
                    } 
    
                    if(kbInfo.event.key == "l" || kbInfo.event.key == "L"){
                        if(this.loadedRoom == "drydock" && this.drydockHemisphereLight) 
                        {
                            if(this.drydockHemisphereLight.isEnabled()){
                                this.drydockHemisphereLight.setEnabled(false);
                                this.scene.ambientColor = this.defaultAmbient;
                            }

                            else if(!this.drydockHemisphereLight.isEnabled()){
                                this.drydockHemisphereLight.setEnabled(true);
                                this.scene.ambientColor = this.debugAmbient;
                            }
                        }
                    }

                    if(kbInfo.event.key == "q" || kbInfo.event.key == "Q"){
                        if(this.drydockAvatarFactory != null){
                            console.log("spawn " + this.spawnAmount);

                            this.scene.blockMaterialDirtyMechanism = true;

                            for(let i = 0; i < this.spawnAmount; i++){
                                let newInstance = this.drydockAvatarFactory.GetInstance(this.scene, this.spawnedDrydockAvatars.toString());
                                this.spawnedDrydockAvatars++;

                                newInstance.setPosition(
                                    this.camera.position.x + Scalar.RandomRange(-this.spawnRadius, this.spawnRadius),
                                    this.camera.position.y + camera.ellipsoid.y * 2,
                                    this.camera.position.z + Scalar.RandomRange(-this.spawnRadius, this.spawnRadius),
                                    this.camera.ellipsoid.y * 2,
                                    this.scene);

                                let down = new BABYLON.Vector3(0, -1, 0);
                                let root = newInstance.GetRoot();
                    
                                let ray = new BABYLON.Ray(root.absolutePosition, down, 200);
                    
                                var hit = this.scene.pickWithRay(ray);
                    
                                let hitPos;
                    
                                if(hit != null){
                                    hitPos = hit.pickedPoint;
                                }
                    
                                if(hitPos){
                                    root.position.y = hitPos.y;
                                }

                                let colorIndex = Math.floor(Math.random() * 10) + 1;
                                newInstance.SetColor(AvatarColor.avatarColors[colorIndex]);

                                newInstance.setRotation(0, Math.random() * Math.PI * 2, 0);

                                newInstance.SetName(faker.name.firstName(), faker.name.lastName());
                                newInstance.SetTitle(faker.company.companyName());
                                newInstance.SetFace(Math.floor(Math.random() * 7) );    
                            }
                        }

                        this.scene.blockMaterialDirtyMechanism = false;
                    } 

                    if(kbInfo.event.key == "e" || kbInfo.event.key == "E"){
                        if(this.drydockAvatarFactory != null){
                            console.log("spawn one at player position");

                            this.scene.blockMaterialDirtyMechanism = true;

                            let newInstance = this.drydockAvatarFactory.GetInstance(this.scene, this.spawnedDrydockAvatars.toString());
                            this.spawnedDrydockAvatars++;

                            newInstance.setPosition(
                                this.camera.position.x,
                                this.camera.position.y,
                                this.camera.position.z,
                                this.camera.ellipsoid.y * 2,
                                this.scene);

                            let colorIndex = Math.floor(Math.random() * 10) + 1;
                            newInstance.SetColor(AvatarColor.avatarColors[colorIndex]);

                            newInstance.setRotation(0, Math.random() * Math.PI * 2, 0);

                            newInstance.SetName(faker.name.firstName(), faker.name.lastName());
                            newInstance.SetTitle(faker.company.companyName());
                            newInstance.SetFace(Math.floor(Math.random() * 7) );    
                        }

                        this.scene.blockMaterialDirtyMechanism = false;
                    }
                    break;    
                }
            });
        }

        this.scene.registerBeforeRender(() => {
            this.elapsedTimeSinceEnableKey += this.engine.getDeltaTime();
        });

        MessageBus.AddListener("ScreenshareStreamStopped",this.screenshareStreamStoppedHandler);
        

    }

    addAlpha = () => {
        this.fullScreenRect.alpha += 0.02;
        console.log(this.fullScreenRect.alpha);
        if(this.fullScreenRect.alpha >= 1){
            this.scene.unregisterBeforeRender(this.addAlpha);
        }
    }

    removeAlpha = () => {
        
        this.fullScreenRect.alpha -= 0.02;
        if(this.fullScreenRect.alpha <= 0){
            this.scene.unregisterBeforeRender(this.removeAlpha);
            this.advancedTexture.removeControl(this.fullScreenRect);
            this.fullScreenRect = null;

            this.advancedTexture.dispose();
            
        }
    }

    
    screenshareStreamStoppedHandler = (data:any) => {
        if(!data.screenshareChannelName || data.screenshareChannelName.length <= 0 ||  data.screenshareChannelName == "" ||  data.screenshareChannelName == this.currentScreenShareChannel){
            this.jumbotronController.SetSlide(this.currentSlideNumber);
            this.currentScreenShareChannel = "";
        }
    }

    onPlayerConnected = () => {

        //Tell the local avatar its ok to start broadcasting its position
        //this.localAvatarController.startSendingPositon();

        //console.log("onPlayerConnectedzzzzzzzzzz");

    };

    onRemotePlayerConnected = (avatarData: AvatarData) => {
        console.log("onRemotePlayerConnected");
        console.log(avatarData);
    };

    onPlayerDataUpdate = (playerData: AvatarData) => {
        
    };

    onRemotePlayerDisconnected = (playerID: AvatarData) => {

    };

    onMessage = (messageName: string, message: string) => {

    };

    onGlobalMessage = (messageName: string, message: string) => {
       
    };

    
    onVariableUpdate = (variableName: string, newValue: any) => { 
        console.log("VARIABLE UPDATE: " + variableName );
        console.log(newValue);  
        if(variableName == "jumbotronScreenShare"){
            if(newValue.screenshareChannelName != this.avatarData.userID + "_screen"){
                if(newValue.screenshareChannelName.length > 0){
                this.currentScreenShareChannel = newValue.screenshareChannelName;
                this.liveSwitchController.joinScreenshare(newValue.screenshareChannelName);
                } else {
                    this.jumbotronController.SetSlide(this.currentSlideNumber);
                }
            }
        } 

        if(variableName == "jumboTronMsg"){
            this.currentSlideNumber = newValue.slideNumber;
            if(this.jumbotronController) this.jumbotronController.SetSlide(this.currentSlideNumber);            
        }
        
    };


    onPlayerPositionsUpdate = (playerData: any) => {
        //console.log("onRemotePlayerConnected");
        //console.log(playerData);
    };

    LoadEnvironment = () : Promise<boolean> => {
        return new Promise<boolean>((resolve,reject)=>{        
            let assetsManager = new BABYLON.AssetsManager(this.scene);
            assetsManager.useDefaultLoadingScreen = false;

            let roomCodeData : RoomCodeStruct;
            if(RoomCodeData.RoomCodeMap){        
                roomCodeData =  RoomCodeData.RoomCodeMap.get(this.avatarData.roomID);
            }

            let rand = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); //https://gist.github.com/6174/6062387
            let randURLString = "?rand=" + rand;

            console.log("URL random = " + rand);

            this.loadedRoom = "neon"; //neon, zoo, drydock

            let neonModelTask;
            let GEModelTask;
            let ZooModelTask;
            let drydockModelTask;

            switch(this.loadedRoom){
                case "neon":
                    neonModelTask = assetsManager.addMeshTask("MainGalleryTask", "", "./assets/models/", "HubArea6-8B.glb" + randURLString);
                    GEModelTask = assetsManager.addMeshTask("GETask", "", "./assets/models/", "Premier+BG6-9B.glb" + randURLString);
                    break;
                case "zoo":
                    ZooModelTask = assetsManager.addMeshTask("ZooTask", "", "./assets/models/", "ZooScene6-30C.glb" + randURLString);
                    break;
                case "drydock":
                    drydockModelTask = assetsManager.addMeshTask("modelViewerTask", "", "./assets/models/", "ViewerModel.glb" + randURLString);
                    break;
                default:
                    return;
            }

            let navMeshFileName = "";

            assetsManager.onFinish = (tasks) => {
                let walkableMeshes : BABYLON.Mesh[] = [];
                let navMeshes : BABYLON.Mesh[] = [];

                if(this.loadedRoom == "neon"){
                    let reflectiveCircleMesh : BABYLON.Mesh;
                    let reflectionMeshes : BABYLON.Mesh[] = [];
                    let animatedTexture : BABYLON.Texture;

                    let meshes = neonModelTask.loadedMeshes;
                    if(meshes != null){
                        for(var i=0;i<meshes.length;i++) {
                            
                            if(meshes[i].name == "__root__")
                            {
                                meshes[i].scaling = new BABYLON.Vector3(1.5, 1.5, -1.5);
                            }

                            if(meshes[i].name == "ReflectiveCircle"){
                                reflectiveCircleMesh = meshes[i] as BABYLON.Mesh;

                                let reflectMaterial = new BABYLON.PBRMaterial("floorMaterial", this.scene);
                                reflectMaterial.metallic = 0;
                                reflectMaterial.roughness = 0;

                                reflectiveCircleMesh.material?.dispose(false, true);
                                reflectiveCircleMesh.material = reflectMaterial;
                            }
                            
                            if(meshes[i].name == "Screen_primitive0"){
                                reflectionMeshes.push(meshes[i] as Mesh);

                                meshes[i].scaling.x = -1;

                                let screenMaterial = new BABYLON.PBRMaterial("jumbotronMaterial", this.scene);
                                screenMaterial.unlit = true;
                                meshes[i].material?.dispose();
                                meshes[i].material = screenMaterial;

                                this.jumbotronController = new JumbotronController(this.engine, this.socketIOController, screenMaterial, this.scene, roomCodeData);
                            }

                            if(meshes[i].name == "RampColliders"){
                                meshes[i].checkCollisions = true;
                                meshes[i].isPickable = false;
                                meshes[i].material?.dispose();
                                meshes[i].visibility = 0;
                                navMeshes.push(meshes[i] as BABYLON.Mesh);
                            }

                            if(meshes[i].name == "FloorCircle"){
                                animatedTexture = meshes[i].material?.getActiveTextures()[0] as Texture;
                            }

                            if(meshes[i].name == "Path_primitive0"){
                                reflectionMeshes.push(meshes[i] as Mesh);
                            }

                            if(meshes[i].name == "Path_primitive1"){
                                meshes[i].checkCollisions = true;
                                walkableMeshes.push(meshes[i] as BABYLON.Mesh);
                                navMeshes.push(meshes[i] as BABYLON.Mesh);
                            }

                            if(meshes[i].name == "Path_primitive2"){
                                reflectionMeshes.push(meshes[i] as Mesh);
                            }

                            if(meshes[i].name == "Triangles_primitive0"){
                                reflectionMeshes.push(meshes[i] as Mesh);
                            }

                            if(meshes[i].name == "Triangles_primitive1"){
                                reflectionMeshes.push(meshes[i] as Mesh);
                            }

                            meshes[i].freezeWorldMatrix();
                        }

                        //Add clickable floor plane
                        let walkingPlane = BABYLON.MeshBuilder.CreatePlane("walkingPlane", {size : 150}, this.scene);
                        walkingPlane.rotation.x = BABYLON.Tools.ToRadians(90);
                        walkingPlane.checkCollisions = true;
                        walkingPlane.visibility = 0;
        
                        walkableMeshes.push(walkingPlane);
                        navMeshes.push(walkingPlane);

                        //Setup mirror texture
                        reflectiveCircleMesh.computeWorldMatrix(true);
                        let mirror_worldMatrix = reflectiveCircleMesh.getWorldMatrix();

                        let mirror_vertexData = reflectiveCircleMesh.getVerticesData("normal");    
                        let mirror_normal = new BABYLON.Vector3(0,0,0);

                        if(mirror_vertexData != null){
                            mirror_normal = new BABYLON.Vector3(mirror_vertexData[0], mirror_vertexData[1], mirror_vertexData[2]);
                        }

                        mirror_normal = BABYLON.Vector3.TransformNormal(mirror_normal, mirror_worldMatrix);

                        let reflector = BABYLON.Plane.FromPositionAndNormal(reflectiveCircleMesh.position, mirror_normal.scale(-1));

                        let reflectTexture = new BABYLON.MirrorTexture("mirror", 512, this.scene, true);
                        reflectTexture.mirrorPlane = reflector;
                        reflectTexture.adaptiveBlurKernel = 32;
                        reflectTexture.renderList = reflectionMeshes;

                        (reflectiveCircleMesh.material as PBRMaterial).reflectionTexture = reflectTexture;

                        //Check for alternate textures
                        if(roomCodeData && roomCodeData.textures){
                            console.log("Alternate texture data found");

                            let floorBake = this.scene.getMaterialByName("FloorBake") as PBRMaterial;
                            if(floorBake.albedoTexture) floorBake.albedoTexture.dispose();
                            floorBake.albedoTexture = new BABYLON.Texture("./assets/textures/" + roomCodeData.textures[0], this.scene, false, false);

                            let emit = this.scene.getMaterialByName("Emit") as PBRMaterial;
                            let emitSolid = this.scene.getMaterialByName("EmitSolid") as PBRMaterial;

                            if(emit.emissiveTexture) emit.emissiveTexture.dispose();
                            if(emitSolid.emissiveTexture) emitSolid.emissiveTexture.dispose();
                            if(animatedTexture) animatedTexture.dispose();

                            let emitTexture = new BABYLON.Texture("./assets/textures/" + roomCodeData.textures[1], this.scene);

                            emit.emissiveTexture = 
                            emitSolid.emissiveTexture = 
                            animatedTexture = 
                            emitTexture;
                        }

                        //Setup texture animation
                        let uOffsetAnimation = new BABYLON.Animation("uOffsetAnimation", "uOffset", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

                        let keys = [];
                        keys.push({
                            frame: 0,
                            value: 0
                        });

                        keys.push({
                            frame: 360,
                            value: 1
                        });

                        uOffsetAnimation.setKeys(keys);

                        animatedTexture.animations = [];
                        animatedTexture.animations.push(uOffsetAnimation);
                        this.scene.beginAnimation(animatedTexture, 0, 360, true);
                        this.scene.resetLastAnimationTimeFrame();

                        //let cube = BABYLON.MeshBuilder.CreateBox("ruler", {height : 1.8}, this.scene);
                        //cube.position.y = .9;
                    }

                    let geMeshes = GEModelTask.loadedMeshes;
                    if(geMeshes != null){
                        //Locate the ground, path, and screen
                        for(var i=0;i<geMeshes.length;i++) {
                            
                            if(geMeshes[i].name == "__root__"){
                                geMeshes[i].scaling = new BABYLON.Vector3(1.6, 1.6, -1.6);
                                geMeshes[i].setAbsolutePosition(new BABYLON.Vector3(0, 1000, 0));
                            }

                            if(geMeshes[i].name == "Floor"){
                                geMeshes[i].checkCollisions = true;
                                walkableMeshes.push(geMeshes[i] as BABYLON.Mesh);
                                navMeshes.push(geMeshes[i] as BABYLON.Mesh);
                            }

                            if(geMeshes[i].name == "BakedPremier_primitive1"){
                                //Create collider around premier box
                                //https://forum.babylonjs.com/t/get-total-size-of-parent-mesh/9285/7

                                let boundingVectors = geMeshes[i].getHierarchyBoundingVectors();

                                let size = {
                                    x: boundingVectors.max.x - boundingVectors.min.x,
                                    y: boundingVectors.max.y - boundingVectors.min.y,
                                    z: boundingVectors.max.z - boundingVectors.min.z
                                }
                                
                                let premierCollider = BABYLON.Mesh.CreateBox("premierCollider", 1, this.scene);

                                let mat = new BABYLON.StandardMaterial("premierMat", this.scene);
                                mat.alpha = 0;

                                premierCollider.material = mat;

                                let scaler = new Vector3(1.5, 1, 1.5);
                                premierCollider.scaling = new BABYLON.Vector3(size.x * scaler.x, size.y * scaler.y, size.z * scaler.z);
                                premierCollider.position = new BABYLON.Vector3(
                                    (boundingVectors.min.x + boundingVectors.max.x) / 2, 
                                    (boundingVectors.min.y + boundingVectors.max.y) / 2,
                                    (boundingVectors.min.z + boundingVectors.max.z) / 2);
                                    
                                premierCollider.checkCollisions = true;
                                premierCollider.isPickable = false;
                                premierCollider.setParent(geMeshes[i]);

                                navMeshes.push(premierCollider);

                                //premierCollider.enableEdgesRendering();
                            }   
                        }

                        for(var i=0;i<geMeshes.length;i++) {
                            geMeshes[i].freezeWorldMatrix();
                        }
                    }

                    let teleportController = new TeleportController(this.engine,this.scene,this.camera,this.socketIOController);

                    navMeshFileName = "navMeshData.txt";
                }

                else if(this.loadedRoom == "zoo"){  
                    this.camera.ellipsoid = new BABYLON.Vector3(0.5, .9, 0.5);
                    this.camera.position.z = 18;

                    let zooMeshes = ZooModelTask.loadedMeshes;
                    if(zooMeshes != null){
                        //Locate the ground, path, and screen
                        for(let i = 0; i < zooMeshes.length; i++) {
                            
                            if(zooMeshes[i].name == "__root__"){
                                zooMeshes[i].scaling = new BABYLON.Vector3(2, 2, -2);
                            }

                            if(zooMeshes[i].name == "Floor"){
                                zooMeshes[i].checkCollisions = true;
                                walkableMeshes.push(zooMeshes[i] as BABYLON.Mesh);
                                navMeshes.push(zooMeshes[i] as BABYLON.Mesh);
                            }

                            if(zooMeshes[i].name == "ScreenFrame"){
                                let cube = BABYLON.MeshBuilder.CreateBox("Screen Collider", {width: 20, height: 15, depth: 2});
                                cube.position = zooMeshes[i].absolutePosition;
                                cube.rotationQuaternion = zooMeshes[i].absoluteRotationQuaternion;

                                cube.checkCollisions = true;
                                navMeshes.push(cube);

                                cube.isPickable = false;
                                cube.visibility = 0;
                            }

                            if(zooMeshes[i].name == "Platform1"){
                                let cylinder = BABYLON.MeshBuilder.CreateCylinder("Animal Platform Collider", {diameter: 5}, this.scene);
                                cylinder.position = zooMeshes[i].absolutePosition;
                                cylinder.scaling.x = 1.5;
                                cylinder.rotation.y = BABYLON.Tools.ToRadians(20);

                                cylinder.checkCollisions = true;
                                navMeshes.push(cylinder);

                                cylinder.isPickable = false;
                                cylinder.visibility = 0;
                            }

                            if(zooMeshes[i].name == "Platform2"){
                                let cylinder = BABYLON.MeshBuilder.CreateCylinder("Animal Platform Collider", {diameter: 5}, this.scene);
                                cylinder.position = zooMeshes[i].absolutePosition;
                                cylinder.scaling.x = 1.39;
                                cylinder.scaling.z = 2;
                                cylinder.rotation.y = BABYLON.Tools.ToRadians(30);

                                cylinder.checkCollisions = true;
                                navMeshes.push(cylinder);

                                cylinder.isPickable = false;
                                cylinder.visibility = 0;
                            }

                            if(zooMeshes[i].name == "Platform3"){
                                let cylinder = BABYLON.MeshBuilder.CreateCylinder("Animal Platform Collider", {diameter: 5.7}, this.scene);
                                cylinder.position = zooMeshes[i].absolutePosition;
                                cylinder.checkCollisions = true;
                                navMeshes.push(cylinder);

                                cylinder.isPickable = false;
                                cylinder.visibility = 0;
                            }

                            if(zooMeshes[i].name == "Skybox"){
                                zooMeshes[i].checkCollisions = true;
                                navMeshes.push(zooMeshes[i] as BABYLON.Mesh);
                            }

                            if(zooMeshes[i].name == "Screen"){
                                zooMeshes[i].scaling.y = -1;

                                let screenMaterial = new BABYLON.PBRMaterial("jumbotronMaterial", this.scene);
                                screenMaterial.unlit = true;
                                zooMeshes[i].material?.dispose();
                                zooMeshes[i].material = screenMaterial;

                                this.jumbotronController = new JumbotronController(this.engine, this.socketIOController, screenMaterial, this.scene, roomCodeData);
                            }

                            //zooMeshes[i].freezeWorldMatrix();
                        }
                    }

                    let zooAnimations = ZooModelTask.loadedAnimationGroups;
                    if(zooAnimations != null){
                        for(let i = 0; i < zooAnimations.length; i++){
                            zooAnimations[i].play(true);
                        }
                    }

                    navMeshFileName = "ZOOnavMeshData.txt";
                }        

                else if (this.loadedRoom == "drydock"){
                    this.scene.ambientColor = this.debugAmbient;

                    this.camera.position.z = 0;

                    let importedModels = drydockModelTask.loadedMeshes;
                    let importedAnimations = drydockModelTask.loadedAnimationGroups;

                    if(importedModels){
                        let ambientColor = new BABYLON.Color3(1,1,1);
                        for(let i = 0; i < importedModels.length; i++) {       
                            if(importedModels[i].name.endsWith("*_col")){
                                importedModels[i].checkCollisions = true;
                            }

                            let mesh = importedModels[i] as BABYLON.Mesh;
                            if(mesh.material){
                                let mat = mesh.material as PBRMaterial;
                                mat.ambientColor = ambientColor;
                            }
                        }
                    }   

                    if(importedAnimations != null){
                        for(let i = 0; i < importedAnimations.length; i++){
                            importedAnimations[i].play(true);
                        }
                    }

                    //let walkingPlane = BABYLON.MeshBuilder.CreatePlane("ground", {size: 500}, this.scene);
                    //walkingPlane.checkCollisions = true;
                    //walkingPlane.rotation.x = BABYLON.Tools.ToRadians(90);
                    //walkingPlane.visibility = 0;         

                    //Code for drydock to randomly add other avatars
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    
                    const param_x = urlParams.get('x');
                    let x = 0;
                    if(param_x){
                        x = parseInt(param_x);
                    }

                    const param_z = urlParams.get('z');
                    let z = 0;
                    if(param_z){
                        z = parseInt(param_z);
                    }

                    const param_radius = urlParams.get('radius');
                    if(param_radius){
                        this.spawnRadius = parseInt(param_radius);
                    }

                    const param_amount = urlParams.get('amount');
                    if(param_amount){
                        this.spawnAmount = parseInt(param_amount);
                    }

                    const param_timeBetween = urlParams.get('time');
                    let timeBetween = 250;
                    if(param_timeBetween){
                        timeBetween = parseInt(param_timeBetween);
                    }
                
                    this.drydockAvatarFactory = new RemoteAvatarInstanceFactory(this.scene);
                    this.drydockAvatarFactory.CreateTemplate();

                    this.drydockHemisphereLight = new BABYLON.HemisphericLight("Drydock Hemispheric Light", Vector3.Up(), this.scene);
                }

                if(navMeshFileName != ""){
                    let playerClickNavController = new PlayerClickNavigationController(this.scene);
                    playerClickNavController.load().then(()=>{
                        let navPlugin = new BABYLON.RecastJSPlugin;
                        let navMeshHandler = new NavMeshHandler(this.scene);
                        navMeshHandler.GetNavData(navPlugin, navMeshes, navMeshFileName).then(()=>{
                            playerClickNavController.SetNewWalkableData(walkableMeshes, navPlugin, this.camera);
                        })
                    });
                }
                
                resolve();
               
            };
            assetsManager.load();
        });
    }  
}